import React, { useEffect, useRef, useCallback } from "react"
import { useLocation } from "react-router-dom"
import PropTypes from "prop-types"

// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import withRouter from "components/Common/withRouter"
import { Link } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"
import roleTypes from "constants/role"

import { useSelector, useDispatch } from "storeRtk"

const SidebarContent = props => {
  const { userData } = useSelector(state => state.authReduxSlice)
  const role =
    userData?.role_type ||
    JSON.parse(localStorage.getItem("authUser"))?.role_type
  // const role = JSON.parse(localStorage.getItem("authUser"))?.role_type
  const path = useLocation()
  const ref = useRef()

  const { type } = props

  console.log('type', type);

  const activateParentDropdown = useCallback(item => {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]

    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item)
      return false
    }
    scrollElement(item)
    return false
  }, [])

  const removeActivation = items => {
    for (var i = 0; i < items.length; ++i) {
      var item = items[i]
      const parent = items[i].parentElement

      if (item && item.classList.contains("active")) {
        item.classList.remove("active")
      }
      if (parent) {
        const parent2El =
          parent.childNodes && parent.childNodes.lenght && parent.childNodes[1]
            ? parent.childNodes[1]
            : null
        if (parent2El && parent2El.id !== "side-menu") {
          parent2El.classList.remove("mm-show")
        }

        parent.classList.remove("mm-active")
        const parent2 = parent.parentElement

        if (parent2) {
          parent2.classList.remove("mm-show")

          const parent3 = parent2.parentElement
          if (parent3) {
            parent3.classList.remove("mm-active") // li
            parent3.childNodes[0].classList.remove("mm-active")

            const parent4 = parent3.parentElement // ul
            if (parent4) {
              parent4.classList.remove("mm-show") // ul
              const parent5 = parent4.parentElement
              if (parent5) {
                parent5.classList.remove("mm-show") // li
                parent5.childNodes[0].classList.remove("mm-active") // a tag
              }
            }
          }
        }
      }
    }
  }

  const activeMenu = useCallback(() => {
    const pathName = path.pathname
    let matchingMenuItem = null
    const ul = document.getElementById("side-menu")
    const items = ul.getElementsByTagName("a")
    removeActivation(items)

    for (let i = 0; i < items.length; ++i) {
      if (
        pathName.split("/")[1] === items[i].pathname.split("/")[1] &&
        pathName.split("/")[2] === items[i].pathname.split("/")[2]
      ) {
        // console.log("CHOSEN SIDE BAR NAV: ", items[i])
        matchingMenuItem = items[i]
      }

      // Previous/Existing
      // if (pathName === items[i].pathname) {
      //   matchingMenuItem = items[i]
      //   break
      // }
    }
    // console.log("matchingMenuItem: ", matchingMenuItem)
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem)
    }
  }, [path.pathname, activateParentDropdown])

  useEffect(() => {
    ref.current.recalculate()
  }, [])

  useEffect(() => {
    new MetisMenu("#side-menu")
    activeMenu()
  }, [])

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" })
    activeMenu()
  }, [activeMenu])

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            {/* ----------------------- ADMIN NAV ----------------------- */}
            {role === roleTypes["ADMIN"] && (
              <>
                <li className="menu-title">{props.t("Menu")} </li>

                <li>
                  <Link to="/admin/dashboard">
                    <i className="bx bx-home-circle" />
                    <span>{props.t("Dashboard")}</span>
                  </Link>
                </li>
                {/* <li>
                  <Link to="/admin/emarketplace">
                    <i className="bx bx-store" />
                    <span> {props.t("eMarketplace")}</span>
                  </Link>
                </li> */}

                <li className="menu-title">{props.t("Admin")} </li>

                <li>
                  <Link to="/#" className="has-arrow">
                    <i className="bx bx-list-ul" />
                    <span>{props.t("Item Master")}</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/admin/item-master/item-specification">
                        {props.t("Item Specification")}
                      </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="/#" className="has-arrow">
                    <i className="bx bx-list-ul" />
                    <span>{props.t("Product Listings")}</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/admin/product-listing-pending">
                        {"Pending for Approval"}
                      </Link>
                    </li>
                    <li>
                      <Link to="/admin/product-listing-published">
                        {"Approved Products"}
                      </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="/#" className="has-arrow">
                    <i className="bx bx-cart" />
                    <span>{props.t("Manage Categories")}</span>
                  </Link>
                  <ul className="sub-menu manage-categories-list">
                    <li>
                      <Link to="/admin/parent-categories">
                        {props.t("Manage Parent Categories")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/admin/sub-categories">
                        {props.t("Manage Sub-Categories")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/admin/sub-sub-categories">
                        {props.t("Manage Sub of Sub Categories")}
                      </Link>
                    </li>
                  </ul>
                </li>

                <li>
                  <Link to="/#" className="has-arrow">
                    <i className="bx bx-list-ul" />
                    <span>{props.t("Agency Orders")}</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/admin/pending-order">{"Pending Orders"}</Link>
                    </li>
                    <li>
                      <Link to="/admin/confirmed-order">
                        {"Confirmed Orders"}
                      </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="/admin/payments-list">
                    <i className="bx bx-money" />
                    <span>{props.t("Payments List")}</span>
                  </Link>
                </li>
                {/* <li>
                  <Link to="/admin/payment-reports">
                    <i className="bx bx-money" />
                    <span>{props.t("Payment Reports")}</span>
                  </Link>
                </li> */}

                {/* <li>
                  <Link to="/#" className="has-arrow">
                    <i className="bx bx-user" />
                    <span>{props.t("Users")}</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/admin/admins">{props.t("Admins")}</Link>
                    </li>
                    <li>
                      <Link to="/admin/merchants">{props.t("Merchants")}</Link>
                    </li>
                    <li>
                      <Link to="/admin/agencies">{props.t("Agencies")}</Link>
                    </li>
                  </ul>
                </li> */}
              </>
            )}
            {/* ----------------------- MERCHANT NAV ----------------------- */}
            {role === roleTypes["MERCHANT"] && (
              <>
                <li className="menu-title">{props.t("Menu")} </li>
                <li>
                  <Link to="/merchant/dashboard">
                    <i className="bx bx-home-circle" />
                    <span>{props.t("Dashboard")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/#" className="has-arrow">
                    <i className="bx bx-store"></i>
                    <span>{props.t("eMarketplace")}</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/merchant/products-draft-item">
                        {"Draft Item"}
                      </Link>
                    </li>
                    <li>
                      <Link to="/merchant/products-for-approval">
                        {"Pending for Approval"}
                      </Link>
                    </li>
                    <li>
                      <Link to="/merchant/products-published">
                        {"Published"}
                      </Link>
                    </li>
                    <li>
                      <Link to="/merchant/products-inactive">{"Inactive"}</Link>
                    </li>
                  </ul>
                </li>
                <li className="menu-title">{props.t("Menu")} </li>
                <li>
                  <Link to="/#" className="has-arrow">
                    <i className="bx bx-list-ul"></i>
                    <span>{props.t("Orders")}</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/merchant/pending-order">
                        {"Pending Orders"}
                      </Link>
                    </li>
                    <li>
                      <Link to="/merchant/active-orders-list">
                        {"Active Orders"}
                      </Link>
                    </li>
                    <li>
                      <Link to="/merchant/order-history-list">
                        {"Order History"}
                      </Link>
                    </li>
                    <li>
                      <Link to="/merchant/payments-list">
                        {"Payments List"}
                      </Link>
                    </li>
                    {/* <li>
                      <Link to="/merchant/returned-orders-list">
                        {"Returned Orders"}
                      </Link>
                    </li> */}
                  </ul>
                </li>
              </>
            )}
            {/* ----------------------- AGENCY NAV ----------------------- */}
            {role === roleTypes["AGENCY"] && (
              <>
                <li className="menu-title">{props.t("Menu")} </li>
                <li>
                  <Link to="/agency/home">
                    <i className="bx bx-home-circle" />
                    <span>{props.t("Home")}</span>
                  </Link>
                </li>
                <li className="menu-title">{props.t("Menu")} </li>
                <li>
                  <Link to="/agency/category" className="has-arrow">
                    <i className="bx bx-store"></i>
                    <span>{props.t("Category")}</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/agency/compare-items">
                        {props.t("Compare Items")}
                      </Link>
                    </li>
                  </ul>
                </li>
              </>
            )}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SidebarContent))
